import React from "react"
import SelectDropdown from "../../../components/SelectDropdown"
import StatusReportDatePicker from "./components/StatusReportDatePicker"
import {useTranslation} from "react-i18next"
import {addDays, compareDesc, max} from "date-fns"
import {gql, useMutation, useQuery} from "@apollo/client"
import {SelectedReport} from "./StatusReportViewer"

import {GetReportFilter, GetReportFilterVariables} from "./types/GetReportFilter"
import {UpdateUnreleasedReportDate, UpdateUnreleasedReportDateVariables} from "./types/UpdateUnreleasedReportDate"
import {formatDate, formatIsoDate} from "../../../utils/dates"
import {GetAllProgramsAndProjects} from "./types/GetAllProgramsAndProjects"
import {GetAllReports, GetAllReportsVariables} from "./types/GetAllReports"
import Grid from "@mui/material/Grid"
import { getReport } from "./StatusReport";

const getAllProgramsAndProjects = gql`
    query GetAllProgramsAndProjects {
        projects: jira_rm_portfolio_project(where: {status_reports_aggregate: {count: {predicate: {_gt:0}}}}) {
            issue_id
            summary
            __typename
        }
        programs: jira_rm_portfolio_program(where: {status_reports_aggregate: {count: {predicate: {_gt: 0}}}}) {
            issue_id
            summary
            __typename
        }
    }
`

const getAllReports = gql`
    query GetAllReports($selectedId: String) {
        status_report_status_report(where: {_or: [{external_program_id: {_eq: $selectedId}}, {external_project_id: {_eq: $selectedId}}]}) {
            id
            state
            report_date
            external_program_id
            external_project_id
        }
    }
`

const getReportFilter = gql`
    query GetReportFilter($reportId: uuid!) {
        report: status_report_status_report_by_pk(id: $reportId) {
            id
            external_project_id
            external_program_id
        }
    }
`

const updateUnreleasedReportDate = gql`
    mutation UpdateUnreleasedReportDate($report_id: uuid!, $report_date: date!) {
        update_status_report_status_report_by_pk(pk_columns: {id: $report_id}, _set: {report_date: $report_date}) {
            id
            report_date
        }
    }
`

interface Props {
    selectedReport: SelectedReport
    setSelectedReport: (report: SelectedReport) => void
}

const StatusReportFilters: React.FC<Props> = ({selectedReport, setSelectedReport}) => {
    const {t, i18n} = useTranslation("translations")

    const [selected, setSelected] = React.useState<string>("")

    const {data, error} = useQuery<GetAllProgramsAndProjects>(getAllProgramsAndProjects)

    const {data: allReports, error: reportsError} = useQuery<GetAllReports, GetAllReportsVariables>(getAllReports, {
        variables: {selectedId: selected},
        skip: !selected,
    })

    const {
        data: reportFilter,
        error: reportFilterError
    } = useQuery<GetReportFilter, GetReportFilterVariables>(getReportFilter, {
        variables: {reportId: selectedReport.reportId},
        skip: selectedReport.reportId === "" || selected !== "",
    })

    const [updateReportDate] = useMutation<UpdateUnreleasedReportDate, UpdateUnreleasedReportDateVariables>(updateUnreleasedReportDate)

    React.useEffect(() => {
        if (reportFilter) {
            setSelected(
                (reportFilter.report?.external_project_id
                    ? reportFilter.report?.external_project_id
                    : reportFilter.report?.external_program_id) || ""
            )
        }
    }, [reportFilter])

    if (error) throw Error(error.message)
    if (reportsError) throw Error(reportsError.message)
    if (reportFilterError) throw Error(reportFilterError.message)

    const reports = () => allReports?.status_report_status_report ?? []
    const report = (reportId: string) => reports().find((report) => report.id === reportId)

    const handleProjectChange = (projectId: string) => {
        setSelected(projectId)
        setSelectedReport({reportId: ""})
    }

    const handleReportChange = (reportId: string) => {
        setSelectedReport({...setSelectedReport, reportId: reportId})
    }

    const handleDateChange = async (date: Date | null) => {
        if (!date) return
        await updateReportDate({
            variables: {
                report_id: selectedReport.reportId,
                report_date: formatIsoDate(date),
            },
            refetchQueries: [getReport],
            awaitRefetchQueries: true,
        })
    }

    const reportsDropdown = (): { name: string; id: string }[] => {
        return (
            reports()
                .slice()
                .sort((left, right) => compareDesc(new Date(left.report_date), new Date(right.report_date)))
                .map((report) => {
                    if (report.state === "unreleased") {
                        return {name: `${t("unreleased")}`, id: report.id}
                    }
                    return {
                        name: `${formatDate(new Date(report.report_date!))} - ${t(report.state)}`,
                        id: report.id,
                    }
                }) ?? []
        )
    }

    const reportDate = (reportId: string): Date => {
        const report = reports().find((report) => report.id === reportId)
        if (!report) return new Date()
        return new Date(report.report_date)
    }

    const minDate = () => {
        const dates = reports()
            .filter((report) => report.state !== "canceled")
            .filter((report) => report.state !== "unreleased")
            .map((report) => new Date(report.report_date))

        if (dates.length === 0) return null
        return addDays(max(dates), 1)
    }

    const generateOptions = () => {
        const projectOptions =
            data?.projects
                .map((project) => {
                    return {name: project.summary, id: project.issue_id}
                }) ?? []
        const programOptions =
            data?.programs
                .map((program) => {
                    return {name: program.summary, id: program.issue_id}
                }) ?? []
        return [...projectOptions, ...programOptions]
            .sort((left, right) => left.name.localeCompare(right.name, i18n.language, {sensitivity: 'base'}))
    }

    return (
        <>
            <Grid container spacing={1}>
                <Grid item>
                    <SelectDropdown
                        key="SelectStatusReportProject"
                        title={t("name")}
                        options={generateOptions()}
                        icon={"project-diagram"}
                        type={"projectId"}
                        handleChange={(event) => handleProjectChange(event.target.value)}
                        value={data ? selected : ""}
                    />
                </Grid>
                <Grid item>
                    <SelectDropdown
                        key="SelectStatusReportVersion"
                        title={t("version")}
                        options={reportsDropdown()}
                        icon={"calendar-alt"}
                        type={"version"}
                        handleChange={(event) => handleReportChange(event.target.value)}
                        value={allReports ? selectedReport.reportId : ""}
                        isDisabled={!selected}
                    />
                </Grid>
                <Grid item>
                    {report(selectedReport.reportId)?.state === "unreleased" && (
                        <StatusReportDatePicker
                            selectedDate={reportDate(selectedReport.reportId)}
                            setNewDate={(date) => handleDateChange(date)}
                            minDate={minDate()}
                        />
                    )}
                </Grid>
            </Grid>
        </>
    )
}

export default StatusReportFilters
